// Importation des styles et des modules
import '../scss/main.scss';

// import barba from '@barba/core';


import { initBootstrap } from "./bootstrap.js"; // Chargement de l'initialisation de Bootstrap

import barba from '@barba/core';

import { bottom, flip, viewport } from '@popperjs/core'; // Modules Popper.js
import { gsap } from "gsap"; // GreenSock Animation Platform pour les animations
import { ScrollSmoother } from 'gsap/ScrollSmoother.js';
import { ScrollTrigger } from "gsap/ScrollTrigger.js";
import { ScrollToPlugin } from 'gsap/ScrollToPlugin.js';
import { SplitText } from 'gsap/SplitText.js';
import { dpkCursor } from 'dpk_cursor';


// Exportation de initBootstrap pour utilisation dans d'autres fichiers
// export { initBootstrap, bottom, viewport };






/////////////////////
// REGISTER PLUGIN //
/////////////////////
gsap.registerPlugin(ScrollTrigger, ScrollSmoother, SplitText);






///////////////////
// SMOOTH SCROLL //
///////////////////
// let smoother = ScrollSmoother.create({
//   wrapper: '#smooth-wrapper',
//   content: '#smooth-content',
//   smooth: 2,
//   smoothTouch: 0.5,
//   effects: true,
// })


////////////////
// DPK CURSOR //
////////////////
const customCursor = new dpkCursor({ ease: 0.25 });

///////////////////
// DETECT MOBILE //
///////////////////

if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
  // document.write("mobile");
  customCursor.destroy();
  // ScrollSmoother.destroy(); DOESN'T EXIST

} else {
  // document.write("not mobile");
}



/////////////////
// BANDSINTOWN //
/////////////////


document.addEventListener("DOMContentLoaded", function () {
  gsap.registerPlugin(ScrollTrigger, ScrollSmoother);
  const smoother = ScrollSmoother.create({
    smooth: 1.2,
    effects: true
  });


  // // Initialisation de Barba.js
  // barba.init({
  //   transitions: [
  //     {
  //       name: "default",
  //       leave(data) {
  //         return gsap.to(data.current.container, {
  //           opacity: 0,
  //           duration: 0.5,
  //           onComplete: () => {
  //             window.scrollTo(0, 0); // Remet le scroll en haut avant de charger la nouvelle section
  //           }
  //         });
  //       },
  //       enter(data) {
  //         return gsap.from(data.next.container, {
  //           opacity: 0,
  //           duration: 0.5
  //         });
  //       }
  //     }
  //   ]
  // });

});



///////////
// SOUND //
///////////

function initSoundToggle() {
  const soundToggles = document.querySelectorAll(".sound-toggle");
  const backgroundAudio = document.getElementById("backgroundAudio");

  if (!backgroundAudio || soundToggles.length === 0) return; // Sécurité

  // On récupère l'état du son dans localStorage
  let isPlaying = localStorage.getItem("isPlaying") === "true";

  function updateButtons() {
    soundToggles.forEach(btn => {
      btn.innerHTML = isPlaying 
        ? '<i class="bi bi-volume-up-fill fs-1"></i>' 
        : '<i class="bi bi-volume-mute-fill fs-1"></i>';
      btn.classList.toggle("on", isPlaying);
    });
  }

  // Appliquer l'état du son au chargement de la page
  if (isPlaying) {
    backgroundAudio.play().catch(() => {
      isPlaying = false;
      localStorage.setItem("isPlaying", isPlaying);
      updateButtons();
    });
  } else {
    backgroundAudio.pause();
  }
  updateButtons();

  // Gérer le clic sur le bouton
  soundToggles.forEach(soundToggle => {
    soundToggle.addEventListener("click", () => {
      isPlaying = !isPlaying;
      localStorage.setItem("isPlaying", isPlaying);
      isPlaying ? backgroundAudio.play() : backgroundAudio.pause();
      updateButtons();
    });
  });
}

// Exécuter le script au chargement et après une transition Barba.js
document.addEventListener("DOMContentLoaded", initSoundToggle);
document.addEventListener("barba:after", initSoundToggle);


///////////////
// TRANSLATE //
///////////////

// Traductions
const translations = {
  en: {
    about1: "Creative and curious, Cyrille Brotto has been traveling the roads for more than twenty years. He hosts concerts, balls and master classes in France and Europe. His participation in numerous groups, thanks to encounters and his own interests, led him to broaden his repertoire and explore both traditional music and hip-hop.",
    about2: "Always attentive, he composes and creates. He has been constantly refining his playing over time. His ability to transport the audience into sensitive, rich, and diverse musical universes makes him a unique and captivating artist. He never loses sight of a simple, yet profound idea: music, first and foremost, makes the body move.",
    about3: "When he plays, it’s a dance – inside, outside – but it’s always a dance !",
    bc:"Ablaye Cissoko & Cyrille Brotto take us on a spiritual and intimate journey, a human adventure that explores the spleen of the uprooted. Ablaye Cissoko & Cyrille Brotto, kora & accordion, ... or the unprecedented encounter between two instruments and between two unique artists.",
    gf:"Two of Europe’s most renowned diatonic accordion masters join forces with electronic loops and machines to create a new dance music experience. Blending folk/trad rhythms with hypnotic grooves, their sound merges the energy of the bal with the pulse of contemporary trance.",
    bm:"This duo is the reflection of two artistic paths which question each other, brush against each other, come together, between compositions and traditional tunes, between complementarity and complicity, between agreements and disagreements, between joust and symbiosis.",
    br:"Camille Raibaud and Cyrille Brotto come together as a duo to offer an inventive ball with a powerful violin/accordion fusion. Together, they rediscover its contours, improvise, compose and develop a very anchored and deep universe.",
    bcLineup:"Ablaye Cissoko: kora, vocals <br>Cyrille Brotto: diatonic accordion",
    gfLineup:"Stéphane Milleret: diatonic accordion <br>Cyrille Brotto: diatonic accordion <br>Michaël Fontanella: bass, Moog, machines",
    bmLineup:"Stéphane Milleret: diatonic accordion <br>Cyrille Brotto: diatonic accordion",
    brLineup:"Camille Raibaud: violin <br>Cyrille Brotto: diatonic accordion",
    projects:"Projects",
    about:"About",
  },
  fr: {
    about1: "Créatif et curieux, Cyrille Brotto sillonne les routes depuis plus de vingt ans. Il anime concerts, bals et master classes en France et en Europe. Sa participation à de nombreuses formations, au gré de rencontres et de ses intérêts propres, l'a amené à élargir son répertoire et à explorer aussi bien les musiques traditionnelles que le hip-hop.",
    about2: "Toujours attentif, il compose, crée et affine son jeu au fil du temps. Sa capacité à nous transporter dans des univers musicaux sensibles, riches et diversifiés font de lui un artiste unique et captivant. Sans jamais perdre de vue d'une idée simple et profonde : la musique fait avant tout bouger le corps.",
    about3: "Quand il joue, ça danse – à l’intérieur, à l’extérieur – mais ça danse toujours !",
    bc:"Ablaye Cissoko & Cyrille Brotto nous emmènent dans un voyage spirituel et intime, une aventure humaine qui explore le spleen des déracinés. Ablaye Cissoko & Cyrille Brotto, kora & accordéon, ... ou la rencontre inédite entre deux instruments et entre deux artistes singuliers.",
    gf:"Deux des maîtres de l’accordéon diatonique les plus renommés d’Europe unissent leurs forces à des boucles et machines électroniques pour créer une nouvelle expérience de musique de danse. Mêlant rythmes folk/trad et grooves hypnotiques, leur son fusionne l’énergie du bal avec le pouls de la transe contemporaine.",
    bm:"Ce duo est le reflet de deux parcours artistiques qui s'interrogent, se frôlent, se rejoignent, entre compositions et airs traditionnels, entre complémentarité et complicité, entre accords et désaccords, entre joute et symbiose.",
    br:"Camille Raibaud and Cyrille Brotto come together as a duo to concoct a creative ball with a powerful violin/accordion fusion. Together, they rediscover its edges; they improvise, compose and develop a universe with deep roots.",
    bcLineup:"Ablaye Cissoko: kora, voix <br>Cyrille Brotto: accordéon diatonique",
    gfLineup:"Stéphane Milleret: accordéon diatonique <br>Cyrille Brotto: accordéon diatonique <br>Michaël Fontanella: bass, Moog, machines",
    bmLineup:"Stéphane Milleret: accordéon diatonique <br>Cyrille Brotto: accordéon diatonique",
    brLineup:"Camille Raibaud: violon <br>Cyrille Brotto: accordéon diatonique",
    projects:"Projets",
    about:"A propos",
  }
};

let polyglot;

// Fonction pour initialiser la langue
export function initLang() {
  // console.log("initLang() exécuté");
  const savedLang = localStorage.getItem("lang") || "fr";
  polyglot = new Polyglot({ locale: savedLang, phrases: translations[savedLang] });

  updateText();
  setupLangButtons();
}


// Mettre à jour les textes traduits
function updateText() {
  document.querySelectorAll("[data-key]").forEach(el => {
    // console.log("Éléments trouvés pour mise à jour :", el.length);
    const key = el.getAttribute("data-key");
    el.innerHTML = polyglot.t(key); // Utilisation de innerHTML pour interpréter les balises HTML
  });
}


// Changer la langue et mettre à jour l'affichage
function changeLanguage(lang) {
  polyglot.locale(lang);
  polyglot.replace(translations[lang]);
  localStorage.setItem("lang", lang);
  updateText();
}

// Ajouter les écouteurs sur les boutons de langue
function setupLangButtons() {
  document.getElementById("lang-fr").addEventListener("click", () => changeLanguage("fr"));
  document.getElementById("lang-en").addEventListener("click", () => changeLanguage("en"));
}





